import { CDN_ROOT } from "../../common/config";
import lit from "../web3/lit";

export const imgUrl = (url: string) => {
    return CDN_ROOT + url;
  };

// fetch NFT tokenURI, later can expand to handle different storage type (https, ipfs, arweave, etc)
export const fetchHead = async (arId: string) => {
  try{
    return await fetch('https://arweave.net/' + arId, {
    method: 'HEAD'
   });
  } catch (err){
    console.log(err);
  }
}

export const fetchContent = async (arId: string) => {
  try{
    return await fetch('https://arweave.net/' + arId, {
    method: 'GET'
   });
  } catch (err){
    console.log(err);
  }
}

export const fetchDisplayContent = async (arId: string) => {
  try{
    let res = await fetchHead(arId);
    const type = res!.headers.get("Content-Type")!.toString();
    if (!type.includes('application/json')) {
      return {
        url: res!.url,
        urlCoverImage: res!.url,
        typeCoverImage: type,
      };
    }

    // get nested image (try decrypt)
    const res2 = await fetchContent(arId);
    const data = await res2?.json();
    data.url = res2!.url;
    
    // check the real arweave link of the content
    if (data.urlCoverImage) data.urlCoverImage = (await fetchHead(data.urlCoverImage))?.url;
    if (data.urlPublic) data.urlPublic = (await fetchHead(data.urlPublic))?.url;
    return data;

  } catch (err){
    console.log(err);
  }
}

export const fetchEncryptedContent = async (arId: string, encryptedSymmetricKey: string, tokenId: string, chainId: number) => {
  try{
    const res = await fetchContent(arId);
    const encryptedFile = await res?.blob();
    const decryptedFile = lit.decryptFile(encryptedFile!, encryptedSymmetricKey, tokenId, chainId);
    return decryptedFile;
    // decrypt
  } catch (err){
    console.log(err);
  }
}
import { ChangeEventHandler, FC, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import DefaultLayout from "../../components/Layout";
import Web3Context from "../../controllers/web3/context";
import { IWeb3Context } from "../../controllers/web3/type";
import NFTGrid from "../../components/NFTGrid";

import Contracts from "../../controllers/contracts";
import { Input, Typography } from "antd";
import { AssetsFetcher } from "../../components/NFTGrid/type";

import PageSelector from "../../components/Selectors/PageSelector";
import { ROUTE_ALL_COPIES, ROUTE_ALL_CREATIONS } from "../../common/route";

interface ParamType {
  pageType: string;
  queryField: string;
  queryParam: string;
}

const AllAssetsPage: FC = ({
}) => {

    const { connect, isTargetNetwork, connectedWallet, targetNetwork, getSigner } = useContext<IWeb3Context>(Web3Context);
    const [paramType, setParamType] = useState<ParamType>();
    const [address, setAddress] = useState<string>();
    const history = useHistory();
    const location = useLocation();

    useEffect(()=>{
      let data = {
        pageType: "",
        queryField: "",
        queryParam: ""
      }

      let pathList = location.pathname.split("/");
      data.pageType = pathList[pathList.length -1];

      if ( location.search != "" ) {
        [data.queryField, data.queryParam] = location.search.split("?")[1].split("=");
      }

      console.log(data);
      setParamType(data);

    }, [location.pathname, location.search])

    useEffect(() => {
        if(!connectedWallet)connect();
        isTargetNetwork();
    }, [])
    
    const fetchCreationAssets: AssetsFetcher = async (offset: number, limit: number) => {
      let contracts = await Contracts(await getSigner(), targetNetwork);     
      console.log(offset, limit);
      console.log(targetNetwork, window.ethereum.networkVersion);
      console.log(contracts.helper.address);
      const result = await contracts.helper.getCreatorTokens(offset, limit);
      return {
        assets: result.creators,
        count: result.meta.count.toNumber()
      };
    }

    const fetchCreatorAssetsByAddress: AssetsFetcher = async (offset: number, limit: number) => {
      let contracts = await Contracts(await getSigner(), targetNetwork);
      const result = await contracts.helper.getCreatorTokensByAddress(paramType?.queryParam!, offset, limit);
      return {
        assets: result.creators,
        count: result.meta.count.toNumber()
      };      
    }

    const fetchCopyAssets: AssetsFetcher = async (offset: number, limit: number) => {
      let contracts = await Contracts(await getSigner(), targetNetwork);
      const result = await contracts.helper.getCopyTokens(offset, limit);
      return {
        assets: result.copies,
        count: result.meta.count.toNumber()
      };
    }

    const fetchCopyAssetsById: AssetsFetcher = async (offset: number, limit: number) => {
      let contracts = await Contracts(await getSigner(), targetNetwork);
      const result = await contracts.helper.getCopyTokensByCreator(paramType?.queryParam!, offset, limit);
      return {
        assets: result.copies,
        count: result.meta.count.toNumber()
      };      
    }

    const fetchCopyAssetsByAddress: AssetsFetcher = async (offset: number, limit: number) => {
      let contracts = await Contracts(await getSigner(), targetNetwork);
      const result = await contracts.helper.getCopyTokensByAddress(paramType?.queryParam!, offset, limit);
      return {
        assets: result.copies,
        count: result.meta.count.toNumber()
      };      
    }

    const getfetcher = (): AssetsFetcher => {
      switch (paramType?.pageType) {
        case "creations":
          if (paramType.queryField  == "address") return fetchCreatorAssetsByAddress;
        return fetchCreationAssets;
        case "copies":
          if (paramType.queryField  == "creatorId") return fetchCopyAssetsById;
          if (paramType.queryField  == "address") return fetchCopyAssetsByAddress;
          return fetchCopyAssets;
        default:
          return fetchCreationAssets;
      }
    }

    // TODO refactor to custom hook?
    const onChangeTextHandle: ChangeEventHandler<HTMLInputElement> = (e) => {
      const { value: inputValue } = e.target;
      if (inputValue.length > 42) return;
      const reg = /^[a-fA-F0-9]+$/;
      if (inputValue === '' || inputValue === '0' || inputValue === '0x' || inputValue.slice(0,2) === "0x" && reg.test(inputValue.slice(2, inputValue.length))){
        setAddress(inputValue);
      }
    }

    const onSearchAddressHandle = ()=>{
      if (address?.length == 42) {
        history.push(location.pathname + "?address=" + address)
      }
    }

    const getTitle = (): string => {
      switch (paramType?.pageType) {
        case "creations":
          return "Explore Creations";
        case "copies":
          return "Explore Copies";
        default:
          return "Explore Creations";
      }
    }
    
    let routeOptions = {
      "copies": {
        description: "View Copies",
        route: ROUTE_ALL_COPIES
      }, 
      "creations": {
        description: "View Creations",
        route: ROUTE_ALL_CREATIONS
      }
    }

    return (
        <DefaultLayout >
            <div className="explore">
                <div className="explore-header">
                  <Typography.Title>{getTitle()}</Typography.Title>
                  <div className='explore-header-input'>
                    {/* <Input.Search
                      onSearch={onSearchAddressHandle} 
                      className="transfer-input" 
                      value={address}
                      placeholder="Search Address" 
                      onChange={onChangeTextHandle}/> */}
                  {
                    paramType?.queryField != "" &&
                    <Typography.Title level={3}>{"Filter: " + paramType?.queryField + "=" + paramType?.queryParam}</Typography.Title>
                  }
                  </div>
                  <div className='explore-header-btn'>
                    <PageSelector defaultKey={paramType?.pageType!} options={routeOptions}/>
                  </div>
                </div>  
                <NFTGrid
                  pageType={paramType?.pageType!}
                  fetchAssets={getfetcher()}
                  imageWidth={300}
                  imageHeight={300}
                  limit={50}/>
              </div>
        </DefaultLayout>
    )
};

export default AllAssetsPage;
import { useState, useEffect, useContext } from 'react';
import { CreatorViewStruct, CopyViewStruct } from '../../controllers/contracts/typechain-types/Helper';
import Web3Context from '../../controllers/web3/context';
import { IWeb3Context } from '../../controllers/web3/type';

type Asset = CreatorViewStruct | CopyViewStruct

interface FetchAssetsResult {
  assets: Asset[],
  count: number
}

type AssetsFetcher = (arg0: number, arg1: number) => Promise<FetchAssetsResult>;

/**
 * Logic to fetch on-chain NFT assets
 * 
 * @param fetchAssets 
 * @param limit 
 * @returns 
 */
const useAssetsFetcher = (
  fetchAssets: AssetsFetcher,
  limit: number
) => {
  
  const { connectedWallet, network, targetNetwork, connect } = useContext<IWeb3Context>(Web3Context);

  const [assets, setAssets] = useState<Asset[]>([]);
  const [page, setPage] = useState<number>(0);
  const [count, setCount] = useState<number>(limit);

  useEffect(() => {
    // clear old assets on function change
    return ()=>{
      setAssets([]);
      setPage(0);
      setCount(limit);
      console.log("unmount");
    }
  }, [fetchAssets])

  useEffect(() => {
    let unmount = false;

    if (network == targetNetwork) {
      if ( assets.length >= count ) return;
      fetchAssets(getOffset(), limit).then(
        (result) =>{
          if (!unmount) {
            setCount(result.count);
            setAssets([...assets, ...result.assets]);
          }
        }
      );
    };
    return ()=>{
      unmount = true;
    }
  }, [network, connectedWallet, page, fetchAssets])

  const getOffset = () => page * limit;

  const getAssets = async () => {
      if ( assets.length >= count ) return;
      return await fetchAssets(getOffset(), limit);
  }

  const fetchMore = () => {
      setPage((prev) => prev + 1);
  }

  return {
    assets: assets,
    fetchMore: fetchMore
  }

}

export default useAssetsFetcher;
import {
    BrowserRouter,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import { Suspense, lazy } from "react";
import { ROUTE_ALL_ASSETS, ROUTE_ALL_COPIES, ROUTE_ALL_CREATIONS, ROUTE_BASE, ROUTE_CREATE, ROUTE_CREATION, ROUTE_COPY, ROUTE_FAQS, ROUTE_MY_ASSETS, ROUTE_MY_CREATIONS, ROUTE_MY_COPIES, ROUTE_FAUCET } from './common/route'
import CreationProvider from './components/CreationContext';
import AllAssetsPage from './page/explore';
import CreationExplorePage from './page/creation';
import FaqsPage from './page/faqs';
import CopyExplorePage from './page/copy';
import FaucetPage from './page/faucet';

const CreationRouter = lazy(() => import('./page/create'));
const HomePage = lazy(() => import('./page/root'));
const MyAssetsPage = lazy(() => import('./page/assets'));

export default function Router() {
    return (
        <BrowserRouter basename='/'>
            {/* <Suspense fallback={<SuspenseScreen logoOnly />}> */}
            <Suspense fallback={<div>Loading ...</div>}>
                <Switch >
                    <Route exact path={ROUTE_BASE}>
                        <HomePage />
                    </Route>
                </Switch>
                <Switch>
                    <Route exact path={ROUTE_CREATE}>
                        <CreationProvider>
                            <CreationRouter />
                        </CreationProvider>
                    </Route>

                    <Route exact path={ROUTE_ALL_ASSETS} >
                        <Redirect to={ROUTE_ALL_CREATIONS} /> 
                    </Route>
                    <Route exact path={[ROUTE_ALL_CREATIONS, ROUTE_ALL_COPIES]} >
                      <AllAssetsPage />
                    </Route>

                    <Route path={ROUTE_CREATION} >
                      <CreationExplorePage/>
                    </Route>
                    <Route path={ROUTE_COPY} >
                      <CopyExplorePage/>
                    </Route>

                    <Route exact path={ROUTE_FAUCET} >
                      <FaucetPage />
                    </Route>

                    <Route exact path={ROUTE_FAQS} >
                      <FaqsPage />
                    </Route>

                    <Route exact path={ROUTE_MY_ASSETS} >
                      <Redirect to={ROUTE_MY_CREATIONS} /> 
                    </Route>
                    <Route exact path={[ROUTE_MY_CREATIONS, ROUTE_MY_COPIES]} >
                      <MyAssetsPage />
                    </Route>

                </Switch>
            </Suspense>
        </BrowserRouter>
    )
}

import { CHAIN_ID } from "./networks";

export const polygon = {
  creator: '0x2093613cD7Eb6f7fBe1CBBF50FfBe7EF60E1c335',
  copy: '0xc6628c1DC3fE70A117680C4C486bFf6b33F95326',
  mintable: '0xe94a29Cf6D9045a17Bbf8a4Ea974b8aC711b4622',
  helper: '0x4a6aEBeE62bbdc60aCFDe5aF62AB92271A09B936',
  test: {
    mockFT: "",
    mockNFT: ""
  }
}

export const ropsten = {
  creator: '0x5517636feAd4708e8D5293ba3536fD321A20e537',
  copy: '0xD192eBE71841DBfaa85Eeb72F256aeB799B1368F',
  mintable: '0x12E1461a486B203ff9f730A6D5597CCDFB4FfB11',
  helper: '0xc79ca855d42023Eab7e657976C8C9D546810205A',
  test: {
    mockFT: '0xB3393c1f1757e012262ddDc5CA13865a170BCd56',
    mockNFT: '0xBEE958dE1b385d2cc4f9b3F6a17Fb2AA0CBde8fe'
  }
}

export const sepolia = {
  creator: '0xe00266ce2787067e1410DbFFEF925A29Eee902Ec',
  copy: '0xb20E2aA6f16F4872105C413063ef78E7410cbA93',
  mintable: '0xaBf3d8d6EDcE1c4dafcF1F220BFd336aB38ac2DD',
  helper: '0x9D956A9c06d8Ed09aD52b2f7150214AA325fE53d',
  test: {
    mockFT: '0x73Ef1581e36Ea29E679f6D22ba0c139Bb1673FB3',
    mockNFT: '0xd767573d7f6838A884a531e84994396460D09Ac9'
  }
}

export const mumbai = {
  creator: '0xA9d8f2C793B3F90F6981348Bd2DFBEd228C7Ae02',
  copy: '0xC5B0d8b6c5F41c850d599c2677eFb6f63AF83817',
  mintable: '0xe6ddba87e7276E4299cCc2c3Ee4334558C6a731c',
  helper: '0x61a1b6C64f57374BF38581c8D77666B896432d63',
  test: {
    mockFT: '0x6c853aA1830591Db08eC31510227C0521935e55D',
    mockNFT: '0x65f78387568462F121bA479d31F40b61d8E990b5'
  }
}

export const getContracts = (network: number) => {
  if (network === CHAIN_ID.POLYGON_MAINNET) return polygon;
  if (network === CHAIN_ID.ROPSTEN) return ropsten;
  if (network === CHAIN_ID.SEPOLIA) return sepolia;
  if (network === CHAIN_ID.POLYGON_TESTNET) return mumbai;
}
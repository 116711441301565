import { CloseOutlined } from "@ant-design/icons";
import { FC } from "react";
import { useHistory } from "react-router";
import { ROUTE_ALL_CREATIONS, ROUTE_BASE, ROUTE_CREATE, ROUTE_FAQS, ROUTE_FAUCET, ROUTE_MY_ASSETS } from "../../common/route";

interface StackedMobileMenuProps {
    toggleMenu: any,
}

const StackedMobileMenu: FC<StackedMobileMenuProps> = ({toggleMenu}) => {
    const history = useHistory();

    const MENU_OPTIONS = [
        { label: 'Home', route: ROUTE_BASE },
        { label: 'Discover', route: ROUTE_ALL_CREATIONS },
        { label: 'Create', route: ROUTE_CREATE },
        { label: 'Faucets', route: ROUTE_FAUCET },
        { label: 'FAQs', route: ROUTE_FAQS },
        { label: 'My Assets', route: ROUTE_MY_ASSETS}
    ];

    const handleMenuClick = (route: string) => {
        toggleMenu();
        history.push(route)
    };

    return (
        <div
            id='menu-mobile'
            style={{
                position: 'fixed',
                zIndex: 5000,
                height: '100vh',
                width: '100vw',
                backgroundColor: '#000000DD',
                top: 0,
                left: 0
            }}
        >
            <div style={{ position: 'relative' }} >
                <CloseOutlined
                    style={{
                        position: 'absolute',
                        top: 30,
                        right: 25,
                        fontSize: 20
                    }}
                    onClick={e => toggleMenu()}
                />
                <div style={{
                    height: '100vh',
                    display: 'flex', 
                    flexDirection: 'column', 
                    position: 'absolute', 
                    textAlign: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    paddingBottom: 50,
                    alignItems: 'center',
                    zIndex: -1,
                    cursor: 'pointer'
                }}>
                    {
                        MENU_OPTIONS.map((o) => <span
                            key={`navbar:${o.route}`}
                            style={{
                                color: '#8E94FF',
                                fontWeight: 600,
                                fontSize: 20,
                                width: 'max-content'
                            }}
                            onClick={e => handleMenuClick(o.route)}
                        >
                            {o.label}
                        </span>)
                    }
                </div>
            </div>

        </div>
    )
};

export default StackedMobileMenu;
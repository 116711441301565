import { FC } from "react";
import { CreationGridItemProps } from '../type';
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import AssetView from "../../AssetView";

import { useHistory } from "react-router";
import { ROUTE_CREATION_ROOT } from "../../../common/route";
import useAssetDisplay from "../../../hooks/useAssetDisplay";
import { selectData } from "../../../controllers/web3/storage/assetFormater";

const CreationNFTGridItem: FC<CreationGridItemProps> = ({
    creation,
    width,
    height
}) => {

  let history = useHistory();
  const data = useAssetDisplay(creation.tokenURI);
  
  const onCickHandle = () => {     
    history.push(ROUTE_CREATION_ROOT + creation.creatorId.toString());
  }
  
  console.log(data, width, height);

  return (
      <div className="image-display-root">
          {data && 
            <div className="image-display" onClick={onCickHandle}>

              {
                data.typePublic && 
                <div className="creation-title float-items">
                {data.typePublic}
              </div>
              }

              <div className="creation-mintables float-items">
                {"Minting "}
                {creation.rules > 0 ? <CheckOutlined/> : <CloseOutlined/>}
              </div>
              
              <div className="creation-copies float-items">
                {"Copies " + creation.copies.toString()}
              </div>
              
              <AssetView data={selectData(data, 0)} width={width} height={height} controls={false} hoverEffects={true}/>
            </div>}
      </div>
  )
};

export default CreationNFTGridItem;
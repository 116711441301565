import { FC, useContext, useEffect, useMemo, useRef, memo } from "react";
import CreationNFTGridItem from "./CreationItem";
import { ScrollContext } from "../ScrollbarContext";
import { IScrollContext } from "../ScrollbarContext/type";
import debounce from "lodash.debounce";
import { NFTGridProps } from './type';
import { CopyViewStruct, CreatorViewStruct } from "../../controllers/contracts/typechain-types/Helper";
import useAssetsFetcher from "../../hooks/useAssetsFetcher";
import CopyNFTGridItem from "./CopyItem";

const NFTGrid: FC<NFTGridProps> = ({
    pageType,
    fetchAssets,
    imageWidth=300,
    imageHeight=300,
    limit=20,
}) => {
    
    const scrollEndRef = useRef(null);

    const { offset } = useContext<IScrollContext>(ScrollContext);
    
    let {assets, fetchMore} = useAssetsFetcher(fetchAssets, limit);

    const fetchMoreDebounce = useMemo(() => debounce(fetchMore, 200), [])

    const fetchNext = (val: any) => {
        // rules to decide when to fetch more
        if (val >= -window.innerHeight*3 && val != -1) {
            fetchMoreDebounce();
        }
    };

    useEffect(() => {
      fetchNext(offset)
    }, [offset])

    return (
            <div className="handleGrid-root" >
                {assets &&
                    pageType == "creations" ?
                    assets.map((asset, i) => {
                        return <CreationNFTGridItem
                            key={`creationOrder:${i}`}
                            creation={asset as CreatorViewStruct}
                            width={imageWidth}
                            height={imageHeight}
                        />
                    }) :
                    assets.map((asset, i) => {
                      return <CopyNFTGridItem
                          key={`copyOrder:${i}`}
                          copy={asset as CopyViewStruct}
                          width={imageWidth}
                          height={imageHeight}
                      />
                  })
                }
              <div id='scroll-end' ref={scrollEndRef} ></div>
            </div>
    )
};

export default memo(NFTGrid);

import { useEffect, useState , useContext, FC, ReactNode} from "react";
import Web3Context from "./context"
import ERCUtils from "./erc/utils";
import detectEthereumProvider from "@metamask/detect-provider";
import { message } from "antd";
import Web3 from "web3";

import { CHAIN_ID } from '../../common/networks';
import { Signer } from "ethers";

interface Web3ProviderProps {
    children: ReactNode
}

const Web3Provider : FC<Web3ProviderProps> = ({ children }) => {

    const [ hasMetamask, setHasMetamask ] = useState<boolean>(false);
    const [ networkId, setNetworkId ] = useState<number>(0);
    const [ targetNetwork, setTargetNetwork ] = useState<number>(CHAIN_ID.POLYGON_TESTNET);
    const [ connectedWallet, setConnectedWallet ] = useState<string>('');
    const [ signer, setSigner ] = useState<Signer>();

    useEffect(() => {
      // listen to chain change event  
      window.ethereum?.on("chainChanged", async (_chainIdInHex: string) => {
          const _chainId = Web3.utils.toNumber(_chainIdInHex);
          setNetworkId(_chainId);
      });  
      return () => window.ethereum?.removeAllListeners();
    }, [])
    

    useEffect(() => {
        detectMetamask();
        initTargetNetwork();
    }, [])


    const detectMetamask = async () : Promise<void> => {
        const provider: any = await detectEthereumProvider();
        setHasMetamask(Boolean(provider));
    }

    const initTargetNetwork = () => {
      let targetNetworkData = localStorage.getItem("TargetNetwork");
      if (targetNetworkData) {
        let targetNetwork = parseInt(targetNetworkData);
        if (Object.values(CHAIN_ID).includes(targetNetwork)) {
          setTargetNetwork(targetNetwork);
          return;
        }
      }
      localStorage.setItem("TargetNetwork", targetNetwork.toString());
    }

    const selectTargetNetwork = async (chainId: number) => {
      if (Object.values(CHAIN_ID).includes(chainId)) {
        try {
            await checkNetworkOrSwitch(chainId);
        } catch (error) {
            throw(error)
        }
        setTargetNetwork(chainId);
        localStorage.setItem("TargetNetwork", chainId.toString());
      }
    }

    const getSigner = async () => {
      if (!signer) {
        let signer_ =  await ERCUtils.getSigner();
        setSigner(signer_);
        return signer_;
      }
      return signer;
    }

    const checkNetworkOrSwitch = async (
        targetNetwork: number        
    ): Promise<void> => {
        try {
          
          if (networkId != targetNetwork) {
                // message.info(`we are switching you to network ` + NETWORKS[targetNetwork].chainName)
                await ERCUtils.switchNetwork(targetNetwork);
            }
        } catch (error) {
            throw(error)
        }
    }

    const isTargetNetwork = async (): Promise<boolean> => {
      try {
        console.log("Target Network", targetNetwork);
        await checkNetworkOrSwitch(targetNetwork);
        return true;
      } catch (error) {
          throw(error)
      }
    }

    const isPolygon = async () : Promise<void> => {
        try {
            await checkNetworkOrSwitch(CHAIN_ID.POLYGON_MAINNET)
        } catch (error) {
            throw(error)
        }
    }

    const isMumbai = async () => {
        try {
            await checkNetworkOrSwitch(CHAIN_ID.POLYGON_TESTNET)
        } catch (error) {
            throw(error)
        }
    }

    const isRopsten = async () => {
        try {
            await checkNetworkOrSwitch(CHAIN_ID.ROPSTEN)
        } catch (error) {
            throw(error)
        }
    }

    const connectERCProvider = async (init: boolean=false) => {
        if (window.ethereum !== undefined && window.ethereum?.isMetaMask) {
            let _connectedWallet = await ERCUtils.connectWallet();
            if (_connectedWallet.length > 0) {
                if (!init) {
                    setConnectedWallet(_connectedWallet[0])
                }
            }
            return _connectedWallet
        } else {
            throw('something went wrong')
        }
    }

    const getEthereumChainId = (): number => {
        return window.ethereum.networkVersion;
    }   

    const connectProvider = async () => {
        try {
            const resp: string[] = await connectERCProvider();
            if (resp.length > 0) {
                // message.info(`connected ${resp[0]}`)
                let _networkId = getEthereumChainId();
                if (typeof _networkId === 'string') {
                    _networkId = parseInt(_networkId)
                }
                setNetworkId(_networkId)
            }
            return resp;            
        } catch (error) {
            console.log(error)
            throw(error);
        }
    };

    const providerValue = {
        signer: signer,
        getSigner: getSigner,
        connectedWallet: connectedWallet,
        hasMetamask: hasMetamask,
        network: networkId,
        targetNetwork: targetNetwork,
        connect: connectProvider,
        selectTargetNetwork: selectTargetNetwork,
        isTargetNetwork: isTargetNetwork,
        isPolygon: isPolygon,
        isMumbai: isMumbai,
        isRopsten: isRopsten
    };
 
    return (
        <Web3Context.Provider value={providerValue}>
            {children}
        </Web3Context.Provider>
    )
};

export default Web3Provider;
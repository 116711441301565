import {
  Mintable__factory,
  Copy__factory,
  Creator__factory,
  Helper__factory,
  MockFT__factory,
  MockNFT__factory
} from "./typechain-types";

import * as utils from './utils';

import { Signer } from 'ethers';
import { IContracts } from "./type";
import { getContracts } from "../../common/contracts";

const Contracts = async (signer: Signer, network: number): Promise<IContracts> => {

  let contracts = getContracts(network);
  
  let Contracts: IContracts = {
    creator: (new Creator__factory(signer)).attach(contracts!.creator),
    copy: (new Copy__factory(signer)).attach(contracts!.copy),
    mintable: (new Mintable__factory(signer)).attach(contracts!.mintable),
    helper: (new Helper__factory(signer)).attach(contracts!.helper),
    test: {
      mockFT: (new MockFT__factory(signer)).attach(contracts!.test.mockFT),
      mockNFT: (new MockNFT__factory(signer)).attach(contracts!.test.mockNFT),
    },
    utils: utils
  }

  return Contracts;

}

export default Contracts;